import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import ServiceCostViewModel from '../../core/features/serviceCosts/ServiceCostViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'ServiceDeliveryPrice',
            viewModel: null,
            activeName: 'amount',
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                description: '',
                origin: '',
                destination: '',
                amountMin: '',
                amountMax: '',
                amount: [0,0],
                status: '',
            },
            origins: [],
            destinations:[],
            maxCost: 0,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR COSTOS DEL SERVICIO',
                onUploadImport: 'on-upload-import-service-costs',
                onCancelImport: 'on-cancel-import-service-costs'
            },
            itemWeightCost: null,
            modalWeightCost: {
                isVisible: false,
                isLoading: false,
                title: 'Configuración de Peso',
            },
            modalCustomerSpecialPrice:{
                isVisible: false,
                isLoading: false,
                title: 'Costos Especiales para Clientes',
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-service-cost', this.onActive)
        this.onMountedEvent('on-upload-import-service-costs', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-service-costs', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-service-cost', this.onActive)
        this.onBeforeDestroyEvent('on-upload-import-service-costs', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-service-costs', this.onCancelImport);
    },
    created() {
        this.viewModel = new ServiceCostViewModel(this)
        this.onActive()
       
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectServiceCost() { },
        onCreate() {
            this.item = this.viewModel.create({  isActive: true})
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        searchOriginFilter(data) {
            if(data) {
                this.addFilter('OriginPlaceId', data.id)
            } else {
                this.removeFilter('OriginPlaceId')
            }
            this.onSearch()
        }, 
        searchDestinationFilter(data) {
            if (data) {
                this.addFilter('DestinationPlaceId', data.id)
            } else {
                this.removeFilter('DestinationPlaceId')
            }
            this.onSearch()
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onSearchResponseMaxCost(response){
            if (this.maxCost === 0) {
                this.maxCost = response.maxCost
                this. searchAmount= [0,this.maxCost]
            }
        },
        searchAmountFilter(data){
            this.addFilter('AmountMin',data[0])
            this.addFilter('AmountMax',data[1])
            this.onSearch()
        },
        onShowWeightCosts() {
            this.viewModel.findWeightCosts()
        },
        onShowCustomerSpecialCost() {
            this.modalCustomerSpecialPrice.isVisible = true
        },
        onFindWeightCostsResponse(response) {
            if(response.data.length === 0) {
                this.itemWeightCost = this.viewModel.createWeightCost()
            } else {
                this.itemWeightCost = this.viewModel.editWeightCost(response.data[0])
            }
            this.modalWeightCost.isVisible = true;
        },
        formatTooltip(val) {
            if (val === this.searchAmount[0]){
               return 'Mín:' + val
            }else{
                return 'Máx: ' + val
            }
          }
    }
}